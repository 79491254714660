import Layout from 'gatsby-theme-carbon/src/components/Layout';
import Main from 'gatsby-theme-carbon/src/components/Main';
import PageHeader from 'gatsby-theme-carbon/src/components/PageHeader';
import Utils from 'gatsby-theme-carbon/src/components/Utils';
import React from 'react';
import { graphql, Link } from 'gatsby';
import ProductsIndexPage from '../../components/ProductsIndex';
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Column
} from "@carbon/react";
import { useContext } from 'react';
import BreadcrubmsContext from '../../util/context/BreadcrubmsContext';
import { useEffect } from 'react';


import axios from 'axios';
import { FourOhFour } from 'gatsby-theme-carbon';
const API_URL = "https://api.industrator.ru/v1";

const getBrandData = async (
  slug
) => {
  // console.log("BRAND", slug)
  const { data: brands } = await axios.get(
    `${API_URL}/brands?filter=%7B%22slug%22%3A%20%22${slug}%22%7D&range=%5B0%2C%2099%5D&sort=%7B%7D`
  );
  // console.log(res);
  // const { data: brands } = res;
  return brands[0]
}


const getProductsAndGetServerData = async (
  {
    params: { slug = 'test' },
  }
) => {
  const brand = await getBrandData(slug);
  return {
    props: {
      brand,
    },
  }
}

const getErrorServerData = () => {
  return {
    headers: {
      status: 500,
    },
    props: {},
  }
}

export async function getServerData(context) {
  // console.log(context)
  try {
    return getProductsAndGetServerData(context)
  } catch (error) {
    console.error(error)
    return getErrorServerData()
  }
}


function IndustratorBrand({ serverData: { brand } }) {
  // console.log(serverData)
  // const { brand } = data;
  // const { brand: brand, products } = serverData.data;

  // const { brand } = data;

  const {
    count,
    isBrands,
    setIsBrands
  } = useContext(BreadcrubmsContext);
  useEffect(() => {
    setIsBrands(true);
  }, []);

  if (!brand) {
    return <FourOhFour />;
  }
  return (
    <Layout
      theme={'white'}
      homepage={false}
      titleType='prepend'
      pageTitle={`Купить продукцию ${brand.article}`}
      pageDescription={`Купить продукцию ${brand.article}`}
      pageKeywords={`Купить продукцию ${brand.article}`}
    >
      <PageHeader
        title={`${brand.article}`}
        label="label"
        tabs={[]}
        theme={'dark'}
      />
      <Main padded>
        <Row>
          <Column>
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="/">
                <Link to={'/'}>Главная
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem href="/brands">
                <Link to={'/brands'}>Бренды
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                {/* <a> */}
                {brand.article}
                {/* </a> */}
              </BreadcrumbItem>
            </Breadcrumb>
          </Column>
        </Row>

        <Row>
          <Column>
            <p className='paragraph' dangerouslySetInnerHTML={{ __html: brand.description }} />
          </Column>
        </Row>

        <ProductsIndexPage hideBrand filters={{ brand: brand.id }} />
      </Main>
      <Utils />
    </Layout >
  )
};

export default IndustratorBrand;